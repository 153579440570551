<template>
  <div class="companies">
    <Actions :items="license.actions" :right="true" />
    <MobileTable
      v-model:selection="store.state.license.selectedLicenses"
      label="Listado de Licencias Pendientes de Aprobar"
      :columns="license.columns"
      :data="store.state.license.pending"
    />
    <DesktopTable
      v-model:selection="store.state.license.selectedLicenses"
      label="Listado de Licencias Pendientes de Aprobar"
      :columns="license.columns"
      :data="store.state.license.pending"
    />
    <MobileTable
      label="Listado de Licencias Aprobadas / Rechazadas"
      :columns="license.columns"
      :data="store.state.license.licenses"
    />
    <DesktopTable
      label="Listado de Licencias Aprobadas / Rechazadas"
      :columns="license.columns"
      :data="store.state.license.licenses"
    />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/licencias/nuevo");
    };
    const onChangeStatus = async (status) => {
      if (!store.state.license.selectedLicenses.length) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setLoading", true);

        const licenses = store.state.license.selectedLicenses.map(
          (item) => item.id
        );

        const response = await store.dispatch(actions.licenseActions.status, {
          licenses,
          status,
        });

        if (response.ok) {
          store.commit("setSelectedLicenses", []);
          await store.dispatch(
            actions.licenseActions.findAll,
            store.state.general.selectedBuilding
          );
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };

    const license = reactive({
      columns: [
        {
          field: "personnel",
          header: "Nombre del Personal",
          sort: true,
        },
        // {
        //   field: "updatedUser",
        //   header: "Usuario",
        //   sort: true,
        // },
        {
          field: "fromDate",
          header: "Fecha de inicio",
          sort: true,
          date: true,
        },
        {
          field: "toDate",
          header: "Fecha de fin",
          sort: true,
          date: true,
        },
        {
          field: "days",
          header: "Cantidad de días",
          sort: true,
        },
        {
          field: "status",
          header: "Estado",
          sort: true,
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/licencias/nuevo"),
        },
        {
          name: "Aprobar",
          action: () => onChangeStatus(2),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/licencias/listado"),
        },
        {
          name: "Rechazar",
          action: () => onChangeStatus(3),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/licencias/listado"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedLicenses", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.licenseActions.findAll,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    return { store, license };
  },
};
</script>

<style scoped></style>
